<template>
  <Modal :show="show" @ok="exportCashflows" @cancel="switchExportModalState">
    <template #header>
      <div class="modal-header">Выгрузка потоков</div>
    </template>
    <template #body>
      <div class="form" v-if="!showSuccessMesage">
        <div class="filter">
          <label class="pax-title">Резервная группа</label>
          <select v-model="exportGroup">
            <option :value='group.id' v-for="group in groups" :key="group.id">{{ group.name }}</option>
          </select>
        </div>
        <div class="filter">
          <label class="pax-title">Валюта</label>
          <select v-model="exportCurrency">
            <option value="1">RUB</option>
          </select>
        </div>
        <div class="filter">
          <label class="pax-title">Модель для оценки</label>
          <select v-model="exportModel">
            <option value="1">GMM</option>
            <option value="2">PAA</option>
          </select>
        </div>
        <div class="filter">
          <label class="pax-title">Тип потоков</label>
          <select v-model="exportType">
            <option value="1">Годовые</option>
          </select>
        </div>
        <div class="filter">
          <label class="pax-title">Отчетная дата</label>
          <select v-model="exportReportDate">
            <option v-for="(date, index) in reportDates" :key="index" :value="date">{{ date }}</option>
          </select>
        </div>
        <div class="filter" v-if="exportType === '2'">
          <label class="pax-title">ПСД</label>
          <input type="checkbox" name="psd" v-model="exportPsd">
        </div>
      </div>
      <div class="message" v-if="showSuccessMesage">
        <h3 class="pax-title">Выгрузка прошла успешно!</h3>
      </div>
      <div class="message" v-if="exportErrorMessage">
        <h3 class="pax-title">Ошибка: {{ exportErrorMessage }}</h3>
      </div>
    </template>
    <template #footer v-if="!inProgress && !showSuccessMesage">
    </template>
    <template #footer v-else-if="showSuccessMesage">
      <button class="modal-default-button" @click="switchSuccessMessageState">Закрыть</button>
    </template>
    <template #footer v-else>
      <h3 class="pax-title">Формируются потоки...</h3>
    </template>
  </Modal>
</template>
  
<script>
import Modal from "@/components/Modal.vue";
export default {
  components: {
    Modal,
  },
  props: {
    show: Boolean,
    start: Function,
    done: Function,
    inProgress: Boolean,
    reportDates: Array,
  },
  data() {
    return {
      showSuccessMesage: false,
      exportModel: '1',
      exportGroup: '1',
      exportReportDate: Date,
      exportPsd: false,
      exportCurrency: '1',
      exportErrorMessage: '',
      exportType: '1',
    }
  },
  watch: {
    reportDates(dates) {
      if(dates.length){
        this.exportReportDate = dates[0]
      }
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    switchExportModalState() {
      this.$emit("switchExportModalState");
    },
    showErrorMessage(message) {
      this.exportErrorMessage = message; 
    },
    hideErrorMessage() {
      this.exportErrorMessage = ''; 
    },
    switchSuccessMessageState() {
      if(this.showSuccessMesage){
        this.hideErrorMessage();
        this.showSuccessMesage = false;
        this.switchExportModalState();
      }else{
        this.showSuccessMesage = true;
      }
    },
    loadData() {
      this.$http
          .get(`/settings`)
          .then(response => {
            this.groups = response.data.groups
            console.log('Groups->loaded(): done')
          })
          .catch(error => console.error('Groups->loaded(): ERROR: ' + error))
    },
    exportCashflows() {
      this.hideErrorMessage();
      const exportFilterData = {
        'group': this.exportGroup,
        'model': this.exportModel,
        'report_date': this.exportReportDate,
        'psd': this.exportPsd,
        'currency': this.exportCurrency,
        'type': this.exportType
      }
      if (this.start()) {
        this.$http
          .post(`/export/cashflows`, exportFilterData, { responseType: 'blob' })
          .then(response => {
            console.log(`Exporting Cashflows`)

            const blob = new Blob([response.data], {type: response.data.type});
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = response.headers.get('content-disposition').split('filename=')[1];
            link.click();

            this.switchSuccessMessageState();
          })
          .catch(error => {
            console.log('Error in cashflows export', error);
            error.response.data.text().then(text => {
              this.showErrorMessage(text);
            })
          })
          .finally(() => this.done())
      }
    }
  }
}
</script>
  
<style scoped>
.modal-header {
  font-weight: bold;
  color: #0074B5;
}

.modal-list-item {
  display: flex;
  align-items: center;
  padding-top: 0.3rem;
}

.modal-list-item input {
  margin-right: 0.5rem;
}

.filter{
  display: flex;
  justify-content: space-between;
}

.filter select {
  width: 7rem;
  margin-left: 2rem;
}

.filter{
  margin-bottom: 1rem;
}
</style>
  
<template>
  <div class="container">
    <div class="top">
      <img src="@/assets/img/kept_logo_2.svg" alt="Kept" class="logo"/>
      <div class="header">
        <TopTypeSelector/>
        <Filter :groups="groups" :active-group="activeGroup" :types="types" :active-type="activeType"
                :report-dates="reportDates" :active-report-date="activeReportDate" :statuses="statuses"
                :active-status="activeStatus" :currencies="currencies" :active-currency="activeCurrency"
                @apply-group="applyGroup" @apply-type="applyType" @apply-report-date="applyReportDate"
                @apply-status="applyStatus" @apply-currency="applyCurrency" @clear-all="clearAll"/>
        <div class="buttons">
          <div v-if="hasPerm('draft') || hasPerm('pending_approval')">
            <router-link to="/upload">Загрузить данные&nbsp;</router-link>
          </div>
          <div v-if="hasPerm('validate')" :class="isDisabled">
            <a @click="process('validate')">Отправить на проверку&nbsp;</a>
          </div>
          <div v-if="hasPerm('approve')" :class="isDisabled">
            <a @click="process('approve')">Утвердить&nbsp;</a>
          </div>
          <div v-if="hasPerm('deny')" :class="isDisabled">
            <a @click="process('deny')">Отклонить утверждение&nbsp;</a>
          </div>
          <div v-if="hasPerm('unlock')" :class="isDisabled">
            <a @click="process('unlock')">Разблокировать расчет&nbsp;</a>
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <LeftTypeSelector/>
      <div class="reports" :key="reload">
        <Grid :title="'Наилучшие оценки'" :items="activeItems([101, 106, 107, 108, 109, 110, 111, 112, 113])"
              @change-state="changeRowState"/>
        <Grid :title="'Рисковые поправки'" :items="activeItems([102, 114])" @change-state="changeRowState"/>
        <Grid :title="'Денежные потоки'" :items="activeItems([103, 104, 116, 117, 127, 128, 129, 130])"
              @change-state="changeRowState"/>
        <Grid :title="'Перестрахование'" :items="activeItems([120, 121, 122, 123, 124, 125, 126])"
              @change-state="changeRowState"/>
      </div>
    </div>
  </div>
</template>

<script>
import TopTypeSelector from '@/components/TopTypeSelector'
import Filter from '@/components/Dashboard/Filter'
import LeftTypeSelector from '@/components/LeftTypeSelector'
import Grid from '@/components/Dashboard/Grid'

export default {
  name: 'Dashboard',
  components: {
    TopTypeSelector,
    Filter,
    LeftTypeSelector,
    Grid,
  },
  data() {
    return {
      polling: null,
      items: [],
      groups: Object,
      activeGroup: 0,
      types: Object,
      activeType: 0,
      reportDates: Object,
      activeReportDate: '1900-01-01',
      statuses: Object,
      activeStatus: 0,
      currencies: Object,
      activeCurrency: 0,
      reload: 0
    }
  },
  computed: {
    isDisabled() {
      return this.items.filter(i => i.isChecked).length ? '' : 'disabled'
    }
  },
  methods: {
    hasPerm(permission) {
      return window.drfUser.permissions.includes('api.' + permission)
    },
    activeItems(types) {
      return this.items.filter(i =>
          (this.activeGroup === 0 || i['group'] === this.activeGroup) &&
          (this.activeType === 0 || i['type'] === this.activeType) && (types.includes(i['type'])) &&
          (this.activeReportDate === '1900-01-01' || i['report_date'] === this.activeReportDate) &&
          (this.activeStatus === 0 || i['status'] === this.activeStatus) &&
          (this.activeCurrency === 0 || i['currency'] === this.activeCurrency))
    },
    createMap(key, value, defaultValue) {
      const map = new Map()
      map.set(defaultValue, '-')
      this.items.forEach(i => map.set(i[key], i[value]))
      return map
    },
    applyGroup(group) {
      this.activeGroup = Number(group)
      this.clearRowsState()
    },
    applyType(type) {
      this.activeType = Number(type)
      this.clearRowsState()
    },
    applyReportDate(date) {
      this.activeReportDate = date
      this.clearRowsState()
    },
    applyStatus(status) {
      this.activeStatus = Number(status)
      this.clearRowsState()
    },
    applyCurrency(currency) {
      this.activeCurrency = Number(currency)
      this.clearRowsState()
    },
    clearAll() {
      this.activeGroup = 0
      this.activeType = 0
      this.activeReportDate = '1900-01-01'
      this.activeStatus = 0
      this.activeCurrency = 0
      this.clearRowsState()
    },
    changeRowState(id) {
      const found = this.items.filter(i => i.id === id)
      if (found.length === 1) {
        found[0].isChecked = !found[0].isChecked
      }
    },
    clearRowsState() {
      this.items.forEach(i => i.isChecked = false)
      this.reload++
    },
    loadReports() {
      this.$http
          .get('/reports')
          .then(response => {
            this.items = response.data.filter(i => i.type !== 105)
            this.groups = this.createMap('group', 'group_name', 0)
            this.types = this.createMap('type', 'type_name', 0)
            this.reportDates = this.createMap('report_date', 'report_date', '1900-01-01')
            this.statuses = this.createMap('status', 'status_name', 0)
            this.currencies = this.createMap('currency', 'currency_name', 0)
            console.log('Dashboard->mounted(): data is loaded')
          })
          .catch(error => console.error('Dashboard->mounted(): ERROR: ' + error))
    },
    process(action) {
      const found = this.items.filter(i => i.isChecked).map(i => i.id)
      if (!found.length) {
        return
      }

      this.$http.post('/reports/process', {'action': action, 'ids': found})
          .then(response => {
            console.log(`Dashboard->process('${action}'): ${response.data.info}`)
          })
          .catch(error => {
            console.error(`Dashboard->process('${action}'): ERROR: ${error}`)
          })
          .finally(() => {
            this.loadReports()
            this.reload++
          })
    },
    pollData() {
      if (!this.polling) {
        this.polling = setInterval(() => {
          this.loadReports()
        }, 10000)
      }
    }
  },
  mounted() {
    this.loadReports()
    // this.pollData()
  },
  unmounted() {
    clearInterval(this.polling)
  }
}
</script>

<style scoped>
.top {
  display: flex;
  align-items: center;
  background-color: #feffff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  padding-top: 1rem;
  width: 100%;
  height: 13rem;
  position: fixed;
  z-index: 1000;
  margin: 0;
  top: 0;
}

.logo {
  margin-left: 2rem;
}

.header {
  position: fixed;
  left: 16rem;
  top: 0;
  width: calc(100% - 17rem);
}

.buttons {
  display: flex;
  padding-bottom: 1rem;
  align-items: start;
}

.buttons div {
  padding: 0.5rem;
  border-bottom: 2px solid #3ebaff;
}

.buttons a {
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  color: #3ebaff;
  white-space: nowrap;
  font-size: 0.95rem;
}

.buttons a:after {
  content: url('@/assets/img/indicator_active.svg');
}

.buttons div.disabled {
  border-bottom: 1px solid #6b6f77;
}

.buttons div.disabled a {
  color: #6b6f77;
}

.buttons div.disabled a:after {
  content: url('@/assets/img/indicator_no_active.svg');
}

.content {
  display: flex;
  background-color: #feffff;
  margin-top: 13rem;
}

.reports {
  padding: 1rem;
  background-color: #efeeee;
  margin-left: 15rem;
  width: 100%;
}
</style>

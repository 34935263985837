<template>
  <div class="filters">
    <div class="filter">
      <label class="pax-title">Резервная группа</label>
      <select @change="activeGroup = Number($event.target.value)" test-id="group_selector">
        <option v-for="[key, value] in groups" :value="key" :key="key" :selected="activeGroup === key">
          {{ value }}
        </option>
      </select>
    </div>
    <div class="filter">
      <label class="pax-title" test-id="type_selector">Тип расчета</label>
      <select @change="activeType = Number($event.target.value)">
        <option v-for="[key, value] in types" :value="key" :key="key" :selected="activeType === key">
          {{ value }}
        </option>
      </select>
    </div>
    <div class="filter">
      <label class="pax-title" test-id="date_selector">Отчетная дата</label>
      <select @change="activeReportDate = $event.target.value">
        <option v-for="[key, value] in reportDates" :value="key" :key="key" :selected="activeReportDate === key">
          {{ value }}
        </option>
      </select>
    </div>
    <div class="filter">
      <label class="pax-title">Валюта</label>
      <input type="text" :value="currency" disabled>
    </div>

    <div class="filter clear">
      <label class="pax-title">Очистить</label>
      <a @click="clear" class="clear" test-id="clear_button">
        <img src="@/assets/img/filter_to_clear.svg" alt="">
      </a>
    </div>
    <div class="filter divider">
      <label class="pax-title">Размерность таблицы</label>
      <select @change="divide($event.target.value)" test-id="table_size_selector">
        <option value="1" :selected="divider === 1">x1</option>
        <option value="1000" :selected="divider === 1000">x1 000</option>
        <option value="1000000" :selected="divider === 1000000">x1 000 000</option>
      </select>
    </div>
    <div v-if="lockStatus?.locked === false" class="filter lock">
      <label class="pax-title">&nbsp;</label>
      <img src="@/assets/img/lock.svg" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Filter',
  props: {
    items: Object,
    lockStatus: Object,
  },
  emits: ['filter', 'divide'],
  data() {
    return {
      applyParams: true,
      groups: Object,
      activeGroup: null,
      types: Object,
      activeType: null,
      reportDates: Object,
      activeReportDate: null,
      currency: null,
      divider: this.$divider.value,
    }
  },
  watch: {
    $route() {
      this.applyParams = false
      this.activeGroup = 0
    },
    items() {
      this.groups = this.createMap(this.items, 'group', 'group_name', 0)
      this.activeGroup = this.applyParams ? Number(this.$route.params.group) || 0 : 0
    },
    activeGroup() {
      const type = Number(this.$route.name.substr(-3))
      let types = [type]
      switch (type) {
        case 101:
        case 106:
        case 107:
        case 108:
        case 109:
        case 110:
        case 111:
        case 112:
        case 113:
          types = [101, 106, 107, 108, 109, 110, 111, 112, 113]
          break
        case 102:
        case 114:
          types = [102, 114]
          break
        case 103:
        case 104:
        case 116:
        case 117:
        case 127:
        case 128:
        case 129:
          types = [103, 104, 116, 117, 127, 128, 129]
          break
        case 115:
          types = [120, 121, 122, 123, 124, 125, 126]
          break
      }
      const items = this.items.filter(i => i['group'] === this.activeGroup && types.includes(i['type']))

      this.types = this.createMap(items, 'type', 'type_name', 0)
      this.activeType = this.applyParams ? Number(this.$route.params.type) || 0 : 0
      this.$emit('filter', null)
    },
    activeType() {
      const items = this.items.filter(i =>
          i['group'] === this.activeGroup &&
          i['type'] === this.activeType)

      this.reportDates = this.createMap(items, 'report_date', 'report_date', '1900-01-01')
      this.activeReportDate = this.applyParams ? this.$route.params.date || '1900-01-01' : '1900-01-01'
      this.$emit('filter', null)
    },
    activeReportDate() {
      const items = this.items.filter(i =>
          i['group'] === this.activeGroup &&
          i['type'] === this.activeType &&
          i['report_date'] === this.activeReportDate)

      if (items.length !== 1) {
        this.currency = '-'
        this.$emit('filter', null)
      } else {
        this.applyParams = false
        this.currency = items[0].currency_name
        this.$emit('filter', items[0].id)
      }
    }
  },
  methods: {
    createMap(items, key, value, defaultValue) {
      const map = new Map()
      map.set(defaultValue, '-')
      items.forEach(i => map.set(i[key], i[value]))
      return map
    },
    clear() {
      this.activeGroup = 0
    },
    divide(value) {
      value = Number(value)
      this.$divider.value = value
      this.$emit('divide', value)
    }
  }
}
</script>

<style scoped>
.filters {
  display: flex;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.filter {
  margin-right: 0.7rem;
}

.divider {
  margin-left: 1rem;
}

label {
  display: block;
  margin-right: 0.7rem;
  margin-bottom: 0.2rem;
  font-size: 0.9rem;
  color: #6b6f77;
}

select {
  width: 10.5rem;
}

input {
  font-size: 0.9rem;
  padding: 0.1rem 0.2rem;
}

.clear img {
  height: 1.25rem;
}

a.clear {
  cursor: pointer;
}

.lock img {
  height: 1.25rem;
}
</style>
